import { transformRecursive } from './recursive'

export function transformAvailablePermissions(permissions) {
  const transformedPermissions = {}
  Object.keys(permissions).forEach((moduleName) => {
    transformedPermissions[moduleName] = transformRecursive(
      permissions[moduleName],
      (permission) => ({
        name: permission.name,
        permissionKey: permission.permission,
        expanded: true,
      }),
      'childPermissions'
    )
  })
  return transformedPermissions
}

export function transformRoleForList(role) {
  return {
    id: role.id,
    name: role.name,
    users: role.users,
    description: role.description,
    roleType: role.roleType,
    canEdit: role.updatebleOob,
    canDelete: role.deleteableOob,
    isDefault: role.isDefault,
  }
}

export function transformRole(role) {
  return {
    id: role.id,
    name: role.name,
    description: role.description,
    roleType: role.roleType,
    users: role.users,
    permissions: role.permissions,
    canEdit: role.updatebleOob,
    isDefault: role.isDefault,
  }
}

export function transformRoleForServer(role) {
  return {
    name: role.name,
    description: role.description,
    users: role.users,
    permissions: role.permissions,
    isDefault: role.isDefault,
  }
}
