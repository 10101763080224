export function transformWorkLog(worklog) {
  return {
    id: worklog.id,
    technicianId: worklog.technicianId,
    description: worklog.description || '',
    timeRange: {
      startTime: worklog.startTime,
      endTime: worklog.endTime,
    },
    createdAt: worklog.createdTime,
  }
}

export function transformWorkLogForServer(worklog) {
  return {
    id: worklog.id,
    technicianId: worklog.technicianId,
    description: worklog.description,
    startTime: worklog.timeRange.startTime,
    endTime: worklog.timeRange.endTime,
  }
}
