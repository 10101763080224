import { generateId } from '@utils/id'

export function transformPatchForList(patch) {
  return {
    guid: generateId(),
    id: patch.id,
    name: patch.name,
    displayName: patch.title,
    subject: patch.title,
    title: patch.title,
    uuid: patch.uuid,
    type: patch.type,
    releaseDate: patch.releaseDate,
    platform: patch.platform,
    arch: patch.arch,
    productId: patch.productId,
    downloadStatus: patch.downloadStatus,
    approvalStatus: patch.patchApprovalStatus,
    testStatus: patch.testStatus,
    approvedOn: patch.approvedOn,
    approvedBy: patch.approvedBy,
    description: patch.description,
    missingSystems: patch.missingSystems,
    installedSystems: patch.installedSystems,
    tags: patch.tags,
    downloadSize: patch.downloadSize,
    downloadOn: patch.downloadOn,
    source: patch.source,
    status: patch.status,
    uploadStatus: patch.uploadStatus,
    affectedProducts: patch.affectedProducts,
    supportUrl: patch.supportUrl,
    // windows specific details
    kbId: patch.kbId,
    severity: patch.severity,
    patchUnInstallationSupported: patch.patchUnInstallationSupported,
    rebootBehaviour: patch.rebootBehaviour,
    category: patch.category,
    supersedingBy: patch.supersedingBy,
    superseded: patch.superseded,
    osApplication: patch.osApplication,
    bulletinId: patch.bulletinId,
    // date field
    canDelete: patch.deleteableOob,
    canEdit: patch.updatebleOob,
    createdAt: patch.createdTime,
    updatedAt: patch.updatedTime,
  }
}

export function transformPatchForDetail(patch) {
  const listProps = transformPatchForList(patch)
  return {
    ...listProps,
    bulletinId: patch.bulletinId,
    manuallyProvided: patch.manuallyProvided,
    affectedProducts: patch.affectedProducts,
    affectedOs: patch.affectedOs,
    platform: patch.platform,
    size: patch.size,
    languageSupported: patch.languageSupported,
    supportUrl: patch.supportUrl,
    hasReplacement: patch.hasReplacement,
    downloadUrl: patch.downloadUrl,
    downloadFileDetails: patch.downloadFileDetails,
  }
}

export function transformPatchForServer(patch) {
  const transformedProps = ['guid']
  const obj = {}
  Object.keys(patch).forEach((paramName) => {
    if (!transformedProps.includes(paramName)) {
      obj[paramName] = patch[paramName]
    }
  })
  if ('description' in patch) {
    obj.description = patch.description || ''
  }
  if ('approvalStatus' in patch) {
    obj.patchApprovalStatus = patch.approvalStatus || ''
  }
  return obj
}

export function transformAutoPatchForList(patch) {
  return {
    id: patch.id,
    name: patch.name,
    displayName: patch.displayName,
    totalTests: patch.totalTests,
    pendingTests: patch.pendingTests,
    completedTests: patch.completedTests,
    lastRunTime: patch.lastRunTime,
    nextRunTime: patch.nextRunTime,
    enabled: !patch.disabled,
    platform: patch.platform,
    autoApproveAfterDays: patch.autoApproveAfterDays,
    notifiedTechnicians: patch.notifiedTechnicians,
    deploymentPolicyId: patch.deploymentPolicyId,
    computerGroupIds: patch.computerGroupIds,
    appSelectionType: patch.appSelectionType,
    patchSeverities: patch.patchSeverities,
    patchCategories: patch.patchCategories,
    ...(patch.appSelectionType === 'inclusion_app'
      ? { osApplicationIds: patch.inclusionOsAppIds }
      : { osApplicationIds: patch.exclusionOsAppIds }),
  }
}

export function transformAutoPatchForServer(patch) {
  return {
    displayName: patch.displayName,
    name: patch.name,
    totalTests: patch.totalTests,
    pendingTests: patch.pendingTests,
    completedTests: patch.completedTests,
    lastRunTime: patch.lastRunTime,
    nextRunTime: patch.nextRunTime,
    disbaled: !patch.disabled,
    autoApproveAfterDays: patch.autoApproveAfterDays,
    notifiedTechnicians: patch.notifiedTechnicians,
    deploymentPolicyId: patch.deploymentPolicyId,
    computerGroupIds: patch.computerGroupIds,
    appSelectionType: patch.appSelectionType,
    patchSeverities: patch.patchSeverities,
    platform: patch.platform,
    patchCategories: patch.patchCategories,
    ...(patch.appSelectionType === 'inclusion_app'
      ? { inclusionOsAppIds: patch.osApplicationIds }
      : { exclusionOsAppIds: patch.osApplicationIds }),
  }
}

export function transformAutoTestDeployment(deployment) {
  return {
    id: deployment.id,
    name: deployment.name,
    displayName: deployment.displayName,
    status: deployment.approvalStatus,
    testStatus: deployment.testStatus,
    createdTime: deployment.createdTime,
    observationStartTime: deployment.observationStartTime,
    remainingApprovalTime: deployment.remainingApprovalTime,
    remoteDeployStage: deployment.remoteDeployStage,
  }
}

export function transformOsApplicationForList(osApplication) {
  return {
    id: osApplication.id,
    name: osApplication.name,
    productCode: osApplication.productCode,
    productType: osApplication.productType,
    family: osApplication.family,
    vendorId: osApplication.vendorId,
    vendorName: osApplication.vendorName,
    platform: osApplication.platform,
    servicePack: osApplication.servicePack,
  }
}

export function transformPatchCommandForList(command) {
  return {
    refId: command.refId,
    id: command.id,
    agentId: command.agentId,
    agentName: command.agentName,
    configId: command.configId,
    configName: command.configName,
    configTitle: command.configTitle,
    // enableRetryForFail: command.enableRetryForFail,
    retryCount: command.retryCount,
    languageId: command.languageId,
    downloadStatus: command.downloadStatus,
    result: command.result,
    ipAddress: command.ipAddress,
    hostName: command.hostName,
    active: command.active,
    expired: command.expired,
    startupRetryCount: command.startupRetryCount,
    refreshRetryCount: command.refreshRetryCount,
    configDownloadStatusId: command.configDownloadStatusId,
    userType: command.userType,
    action: command.action,
    deploymentPolicyId: command.deploymentPolicyId,
    origin: command.origin,
    configType: command.configType,
    remoteOfficeId: command.remoteOfficeId,
    startTime: command.startTime,
    endTime: command.endTime,
    commandTarget: command.commandTarget,
    lastStateChangeTime: command.lastStateChangeTime,
    state: command.state,
    workType: command.workType,
    severity: command.severity,
    createdAt: command.createdTime,
    updatedAt: command.updatedTime,
  }
}
