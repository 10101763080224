export function buildImageUrlFromRefFileName(refFileName) {
  return `/api/public/renderImage/${refFileName}`
}
// export function buildImageUrlFromRefFileName(refFileName) {
//   return `/api/public/download/file/${refFileName}`
// }

export function transformAttachment(
  attachment,
  hasToken = true,
  isPortalLogin = false
) {
  return {
    uid: attachment.refFileName,
    name: attachment.realName,
    realName: attachment.realName,
    refFileName: attachment.refFileName,
    status: 'done',
    url: hasToken
      ? isPortalLogin
        ? `/api/cportal/download/file/${attachment.refFileName}?mid=`
        : `/api/download/file/${attachment.refFileName}?mid=`
      : `/api/public/download/file/${attachment.refFileName}`,
  }
}

export function transformAttachmentForServer(data) {
  return {
    refFileName: data.refFileName,
    realName: data.realName,
  }
}
